.container-footer {
  background-image: url("../images/footer.png");
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: Poppins;
  font-size: clamp(10px, 1.2vw, 20px);
  font-weight: 300;
  color: white;
}
.email-footer {
  text-align: center;
}
.pin-footer {
  text-align: center;
}
.link-footer img {
  width: 20%;
  margin-bottom: 10%;
}
.pin-footer img {
  width: 10%;
}
.email-footer img {
  width: 20%;
}
.link-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.link-footer a {
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 420px) {
  .container-footer {
    font-size: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .email-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
  }
  .pin-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
  }
  .link-footer {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pin-footer img {
    width: 25%;
    margin-top: 5%;
  }
  .email-footer img {
    width: 25%;
    margin-top: 5%;
  }
}
