.container__form {
  background-color: white;
}
.title__contact {
  padding-block: 5%;
  margin: 0;
  text-align: center;
  color: black;
  font-family: Poppins;
  font-weight: bold;
  font-size: 1.875vw;
}
.form {
  font-family: OpenSans;
  font-size: 1.66vw;
}
.first-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nume {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.nume input {
  width: 90%;
  height: 10vh;
  min-height: 50px;
  margin: 0;
  margin-bottom: 7%;
  padding-left: 5%;
  cursor: text;
  border: solid 2px black;
  border-radius: 15px;
}
.publicatii {
  width: 50%;
  margin: 0 auto;
}
.publicatii textarea {
  width: 90%;
  margin: 0;
  margin-bottom: 7%;
  padding-left: 5%;
  padding-top: 2%;
  height: 25vh;
  resize: none;
  border: solid 2px black;
  border-radius: 15px;
  background-color: white;
}
.checkbox {
  display: flex;
  align-items: center;
  width: 50%;
  margin-inline: auto;
}
.checkbox input {
  width: 30px;
  height: 30px;
  margin-right: 4%;
  padding-top: 1%;
}
.buton-submit {
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: center;
  margin-bottom: 7%;
  width: 12%;
}
.buton-submit img {
  width: 100%;
}
.container-button {
  text-align: center;
  margin-top: 3%;
}
.buton-submit:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.form__loader {
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: formLoaderAnimation 1s linear infinite;
}
.grecaptcha-badge {
  visibility: hidden;
}
@keyframes formLoaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .form {
    padding: 0 5%;
    text-align: center;
  }
  .form input:focus {
    outline: none;
  }
  .form textarea:focus {
    outline: none;
  }

  .first-row {
    flex-direction: column;
  }

  .nume,
  .prenume,
  .publicatii,
  .comentarii {
    width: 100%;
    margin: 0;
  }

  .nume input {
    width: 100%;
    border: solid 2px #f2f2f2;
    border-radius: 5px;
    height: 5vh;
    padding-left: 2%;
    font-size: 3vw;
    margin-bottom: 4%;
  }
  .publicatii textarea {
    resize: none;
    border: solid 2px #f2f2f2;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    height: 15vh;
    font-size: 3vw;
    padding-left: 1%;
    margin-bottom: 4%;
  }
  .buton-submit {
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 7%;
    width: 50%;
  }
  .buton-submit img {
    width: 100%;
  }
  .container-button {
    text-align: center;
  }
  .title__contact {
    margin-top: 10%;
    font-size: 5vw;
  }
  .checkbox{
    font-size: 2.5vw;
  }
  .checkbox input {
    width: 20px;
    height: 20px;
    padding-top: 0;
  }
}
