@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.title-clients {
  text-align: center;
  color: black;
  font-size: 1.875vw;
  font-family: Poppins;
  margin-block: 5%;
  font-weight: bold;
}
.carusel {
  margin-block: 10%;
}
.imagine-carusel {
  width: 80%;
  margin-bottom: 35%;
}

@media screen and (max-width: 1024px) {
  .title-clients {
    font-size: 5vw;
    margin-inline: 20%;
  }
  .imagine-carusel {
    width: 90%;
  }
  .imagine-carusel{
    margin-bottom: 25%;
  }
}
