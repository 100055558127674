.container-services {
  background-color: #f2f2f2;
  display: flex;
  font-family: Poppins;
  font-size: 1.25vw;
}
.left {
  width: 40%;
}
.right {
  width: 60%;
}
.fundal {
  width: 100%;
}
.title-services {
  font-family: Poppins;
  color: black;
  font-size: 1.875vw;
  font-weight: bold;
  text-align: left;
  margin-top: 4%;
  margin-left: 18.8%;
}
.small-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.small-left ul {
  list-style-type: none;
  font-family: OpenSans;
  color: black;
  font-size: 1.25vw;
  padding: 0;
}
.lista1 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon1 {
  width: 7%;
}
.container-text1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 10%;
  margin-right: 6%;
  padding-bottom: 5%;
  border-bottom: 1px solid gray;
}
.text-lista1 {
  margin-block: 0;
}
.link1--services {
  margin-left: 36%;
  color: black;
  font-weight: bold;
}
.link1--services:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.lista2 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon2 {
  width: 7%;
}
.container-text2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 10%;
  margin-right: 6%;
  padding-bottom: 5%;
  border-bottom: 1px solid gray;
}
.text-lista2 {
  margin-block: 0;
}
.link2--services {
  margin-left: 36%;
  color: black;
  font-weight: bold;
}
.link2--services:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.lista3 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon3 {
  width: 7%;
}
.text-lista3 {
  width: 100%;
  margin-block: 0;
  margin-left: 10%;
  border-bottom: 1px solid gray;
  margin-right: 6%;
  padding-bottom: 5%;
}
.lista4 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon4 {
  width: 7%;
}
.text-lista4 {
  width: 100%;
  margin-block: 0;
  margin-left: 10%;
  border-bottom: 1px solid gray;
  margin-right: 6%;
  padding-bottom: 5%;
  padding-top: 1.3%;
}
.lista5 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon5 {
  width: 7%;
}
.text-lista5 {
  width: 100%;
  margin-block: 0;
  margin-left: 10%;
  border-bottom: 1px solid gray;
  margin-right: 6%;
  padding-bottom: 5%;
  padding-top: 1.3%;
}
.lista6 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon6 {
  width: 7%;
}
.text-lista6 {
  width: 100%;
  margin-block: 0;
  margin-left: 10%;
  border-bottom: 1px solid gray;
  margin-right: 6%;
  padding-bottom: 5%;
  padding-top: 1.3%;
}
.lista7 {
  display: flex;
  align-items: flex-start;
  margin-left: 5%;
  margin-right: 10%;
  padding-bottom: 3%;
}
.icon7 {
  width: 7%;
}
.text-lista7 {
  width: 100%;
  margin-block: 0;
  margin-left: 10%;
  margin-right: 6%;
  padding-bottom: 3%;
  padding-top: 1.8%;
}
.getstarted--services {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 2%;
}
.getstarted--services:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.links--services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6vw;
  margin-top: 0;
  margin-left: 17%;
}
.contactsales--services {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: black;
  font-family: OpenSans;
  font-size: 1.25vw;
  font-weight: bold;
  margin-bottom: 0;
  margin-left: 4%;
  width: 45%;
}
.contactsales--services:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.getstartedlink--services {
  width: 30%;
  text-align: center;
}
.arrow--services {
  margin-left: 1%;
}
@media screen and (max-width: 1024px) {
  .left {
    display: none;
  }
  .right {
    width: 100%;
  }
  .icon1,
  .icon2,
  .icon3,
  .icon4,
  .icon5,
  .icon6,
  .icon7 {
    display: none;
  }
  .lista1,
  .lista2,
  .lista3,
  .lista4,
  .lista5,
  .lista6,
  .lista7 {
    font-size: 3.5vw;
    text-align: center;
  }
  .container-text1,
  .container-text2 {
    flex-direction: column;
    text-align: center;
    font-size: 3.5vw;
    padding-bottom: 3%;
  }
  .link1--services,
  .link2--services {
    margin-inline: 0;
    margin-top: 8%;
  }
  .title-services {
    text-align: center;
    margin-inline: 0;
    margin-bottom: 8%;
    font-size: 5vw;
    padding-top: 3%;
  }
  .links--services {
    align-items: center;
    margin-inline: 0;
    margin-bottom: 0;
  }
  .getstartedlink--services {
    width: 50%;
  }
  .contactsales--services {
    justify-content: center
    ;
    margin-inline: 0;
    font-size: 4.25vw;
  }
  .arrow--services {
    padding-bottom: 3%;
  }
}
