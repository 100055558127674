.navigation__desktop {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 8vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
  font-size: 1vw;
  font-weight: bold;
}
.nav__desktop {
  display: flex;
}
.nav__desktop a {
  margin-inline: 2vw;
  color: white;
  text-decoration: none;
}
.nav-text1:hover {
  transform: scale(1.15);
  transition: 0.5s;
}
.nav-text2:hover {
  transform: scale(1.15);
  transition: 0.5s;
}
.nav-text3:hover {
  transform: scale(1.15);
  transition: 0.5s;
}
.nav-text4:hover {
  transform: scale(1.15);
  transition: 0.5s;
}
.logo-soma {
  width: 10%;
  height: auto;
  margin-left: 5%;
}
.logo-soma img {
  width: 70%;
}
.logo-soma img:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.get-offer__nav {
  width: 10%;
  height: auto;
  margin-left: 5%;
}
.get-offer__nav img {
  width: 70%;
}
.get-offer__nav img:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.burger-mobile {
  display: none;
}
@media screen and (max-width: 630px) {
  .navigation__desktop {
    display: none;
  }
  .burger-mobile {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(56px);
    width: 100%;
  }
  .logo-burger {
    width: 20%;
    margin-left: 32.5%;
    padding-top: 1%;
  }
  .logo-burger img {
    width: 100%;
  }
  .burger-mobile ul {
    margin-top: 0;
  }
}

