.container-firstpart {
  font-family: Poppins;
  text-align: center;
  background-image: url("../images/background-image.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.text__home1 {
  padding-top: 9%;
  margin-bottom: 1%;
  color: white;
  margin-top: 0;
  font-size: 2.0833vw;
  font-weight: bold;
}
.text__home2 {
  width: 100%;
  color: white;
  margin-top: 0;
  font-size: 1.875vw;
  font-weight: 300;
}
.cards {
  display: flex;
  justify-content: center;
  gap: 3vw;
  margin-top: 5%;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 236px;
  height: 357px;
  border-radius: 38px;
  color: white;
}
.card div {
  font-size: clamp(16px, 3vw, 50px);
  font-weight: bold;
  font-family: Poppins;
}
.card h3 {
  font-family: Roboto;
  font-size: clamp(20px, 1.25vw, 40px);
  color: white;
}
.card1 {
  background-image: url("../images/Card1.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.card2 {
  background-image: url("../images/Card2.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.card3 {
  background-image: url("../images/Card1.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.card4 {
  background-image: url("../images/Card3.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.card4 h3 {
  color: black;
}
.card4 div {
  color: #56cc97;
}
.card5 {
  background-image: url("../images/Card1.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.getstarted--home {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 2%;
}
.getstarted--home:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.links--home {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  margin-top: 3%;
}
.contactsales--home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-family: OpenSans;
  font-size: 1.25vw;
  font-weight: bold;
  margin-bottom: 3%;
  width: 50%;
}
.contactsales--home:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.getstartedlink--home {
  width: 17%;
  text-align: center;
}
.arrow--home {
  margin-left: 2%;
}
@media screen and (max-width: 768px) {
  .text__home1 {
    display: none;
  }
  .text__home2 {
    display: none;
  }
  .cards {
    flex-direction: column;
    align-items: space-evenly;
    padding-top: 7%;
  }
  .card {
    width: 90%;
    min-height: 70px;
    height: 6vh;
    margin-inline: auto;
    border-radius: 10px;
    flex-direction: row;
    justify-content: space-between;
  }
  .card h3 {
    order: -1;
    width: 20%;
    font-size: 3vw;
    margin-left: 2%;
  }
  .card div {
    margin-right: 5%;
    font-size: 6vw;
  }
  .card1,
  .card2,
  .card3,
  .card5 {
    background-image: url("../images/card1-mobile.svg");
  }
  .card4 {
    background-image: url("../images/card2-mobile.svg");
  }
  .getstartedlink--home {
    width: 50%;
  }
  .contactsales--home {
    font-size: 4.25vw;
  }
  .arrow--home {
    padding-bottom: 3%;
  }
}
