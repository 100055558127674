.container-network {
  background-color: white;
  padding-bottom: 5%;
}
.websites {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.website {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1%;
  width: 280px;
  height: 200px;
  border-radius: 20px;
  background-color: #e0f5f3;
  background-image: url(../images/background-card-network.svg);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}
.website img {
  width: 50%;
  max-height: 85%;
  object-fit: contain;
}
.logo__network {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 90%;
  border-bottom: 2px solid #56cc97;
}
.title__websites {
  text-align: center;
  font-family: Poppins;
  font-size: 1.66vw;
  color: #56cc97;
  font-weight: bold;
}
.website-link {
  color: black;
  font-size: clamp(10px, 2vw, 20px);
  font-family: Poppins;
  font-weight: 300;
  margin-bottom: 10%;
}
.get-negru__network {
  width: 100%;
  margin-top: 20%;
  margin-bottom: 7%;
}
.get-negru__network:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.title__network {
  color: black;
  text-align: center;
  font-family: Poppins;
  font-size: 1.875vw;
  font-weight: bold;
  padding-top: 4%;
}
.clicked-button {
  background-color: #e9f3f2;
  font-weight: bold;
  padding: 1%;
  border-radius: 5px;
}
.container-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: Poppins;
  font-size: 1.66vw;
  color: black;
  margin-block: 5%;
  font-weight: 300;
  cursor: pointer;
  border-bottom: 3px solid #f2f2f2;
}
.website-link a {
  color: black;
  text-decoration: underline;
}
.container-buttons div:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.links--network {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactsales--network {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-family: OpenSans;
  font-size: 1.25vw;
  font-weight: bold;
  margin-bottom: 1%;
  width: 50%;
}
.contactsales--network:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.getstarted--network {
  width: 17%;
  text-align: center;
}
.arrow--network {
  margin-left: 1%;
}

@media screen and (max-width: 768px) {
  .title__network {
    font-size: 5vw;
  }
  .website {
    height: 13vh;
    width: 40%;
    margin: 3%;
    border-radius: 10px;
    min-height: 130px;
  }
  .website-link {
    font-size: clamp(10px, 2vw, 20px);
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .logo__network img {
    width: 70%;
  }
  .container-buttons {
    font-size: 2.7vw;
  }
  .getstarted--network {
    width: 50%;
  }
  .contactsales--network {
    font-size: 4.25vw;
  }
  .arrow--network {
    padding-bottom: 3%;
  }
}
